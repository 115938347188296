import React from "react"
import styles from '../styles/index';
import { 
  withStyles,
  Container
} from '@material-ui/core';
import { Helmet } from "react-helmet"

function IndexPage (props) {
  const { classes } = props;

  return (
    <div className={classes.outer}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GemDoc - Coming Soon!</title>
      </Helmet>
      <Container maxWidth="sm">
        <img src="/gemdoc-logo.svg" className={classes.logo} />
        <div className={classes.text}>Coming Soon!</div>
      </Container>
    </div>
  )
}

export default withStyles(styles)(IndexPage)
