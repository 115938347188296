const styles = theme => ({
  outer: {
    marginTop: 200,
    textAlign: 'center',
    color: '#fff',
    padding: 50
  },
  logo: {
    width: 300,
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: 250,
    }
  },
  text: {
    fontSize: 23,
    letterSpacing: 2,
    marginBottom: 10
  },
  link: {
    color: '#fff'
  }
});

export default styles
